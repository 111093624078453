exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-aqb-voices-js": () => import("./../../../src/pages/aqb-voices.js" /* webpackChunkName: "component---src-pages-aqb-voices-js" */),
  "component---src-pages-archive-js": () => import("./../../../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-calendar-js": () => import("./../../../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-funding-js": () => import("./../../../src/pages/funding.js" /* webpackChunkName: "component---src-pages-funding-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learningresources-js": () => import("./../../../src/pages/learningresources.js" /* webpackChunkName: "component---src-pages-learningresources-js" */),
  "component---src-pages-ongoing-js": () => import("./../../../src/pages/ongoing.js" /* webpackChunkName: "component---src-pages-ongoing-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-course-js": () => import("./../../../src/templates/course.js" /* webpackChunkName: "component---src-templates-course-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-learning-resource-js": () => import("./../../../src/templates/learningResource.js" /* webpackChunkName: "component---src-templates-learning-resource-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-partner-js": () => import("./../../../src/templates/partner.js" /* webpackChunkName: "component---src-templates-partner-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-working-group-js": () => import("./../../../src/templates/workingGroup.js" /* webpackChunkName: "component---src-templates-working-group-js" */)
}

